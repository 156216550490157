<template>
  <div class="recharge-manager">
    <div class="sctp-bg-white">
      <div class="sctp-pad-lr15 sctp-pad-tb10">
        <el-form :rules="formRules" size="small" ref="form" :model="form" label-width="80px">
          <div class="sctp-pad-tb20">
            您当前的余额：<span class="sctp-font-16 sctp-bold">{{ (wallet || 0).toMoney() }}</span> 元
            <template v-if="waitWithdrawWallet">
              (提现待审核金额：<span class="sctp-font-16 sctp-bold">{{ waitWithdrawWallet.toMoney() }}</span> 元)
            </template>
            <template v-if="reward">
              (奖励金：<span class="sctp-font-16 sctp-bold">{{ reward.toMoney() }}</span> 元)
            </template>
          </div>
          <el-form-item label="充值金额" prop="money">
            <el-input v-model="form.money">
              <template slot="append">元</template>
            </el-input>
            <div class="el-upload__tip">最高可充100,000.00元</div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitClick">立即充值</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog width="500px" center title="余额充值" :visible.sync="payConfig.showFlag">
      <pay :can-use-wallet="false"
           @pay="payConfig.onPay"
           :pay-amount="form.money"
      ></pay>
    </el-dialog>
  </div>
</template>

<script>
import validator from '../../../../resources/js/async-validator'
import {wallet} from "../../../../apis";

export default {
  name: 'account_recharge',
  data() {
    return {
      form: {
        money: null
      },
      payConfig: {
        showFlag: false,
        showPayDialog: () => {
          this.payConfig.showFlag = true
        },
        onPay: ({payWay}) => {
          const {money} = this.form;
          return this.$request.post({
            reqcode: '1131',
            reqdesc: '钱包充值',
            money: money,
            payWay: payWay,
            userId: this.user.userId
          }).then(res => {
            this.payConfig.showFlag = false;
            this.$refs.form.resetFields();
            this.$message.success('操作成功');
            this.getWallet()
          })
        }
      },
      wallet: 0,
      waitWithdrawWallet: 0,
      reward: null,
      formRules: {
        money: [
          {required: true, message: '请输入充值金额', trigger: 'blur'},
          {validator: validator.validMoney, message: '金额不合法', trigger: ['change', 'blur']},
          {
            validator: (rule, value, callback) => {
              let money = parseFloat(value);
              if (money > 100000 || money < 1) {
                callback(new Error(rule.message))
              } else {
                callback();
              }
            }, message: '可充值范围1 ~ 100,000.00元', trigger: ['change', 'blur']
          },
        ]
      }
    }
  },
  components: {
    pay: () => import('@CMP/common/BuyPay')
  },
  methods: {
    onSubmitClick() {
      this.$refs.form.validate(res => {
        if (res) {
          this.payConfig.showFlag = true
        }
      })
    },
    /**
     * 充值逻辑
     */
    doSubmit() {
      this.$message.success('success')
    },
    getWallet() {
      return wallet.getWallet({
        userId: this.user.userId
      }).then(res => {
        const {retdata = 0, waitAuthWallet = 0, reward} = res;
        this.wallet = retdata;
        this.waitWithdrawWallet = waitAuthWallet;
        this.reward = reward;
      })
    }
  },
  beforeMount() {
    this.getWallet()
  }
}
</script>

<style scoped lang="scss">
.recharge-manager {
  ::v-deep .el-form-item:not(.auto) {
    width: 400px;
  }
}
</style>
